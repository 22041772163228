/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~nouislider/distribute/nouislider.min.css";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "~react-bootstrap-typeahead/css/Typeahead-bs4.css";
@import "~react-datepicker/dist/react-datepicker.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";

/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/widgets";
@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/accordions";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/tooltips";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/popovers";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/bootstrap-alerts";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
@import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/portfolio";
@import "../assets/styles/components/pricing-table";
@import "../assets/styles/components/email/mail-list-container";
@import "../assets/styles/components/email/mail-sidebar";
@import "../assets/styles/components/email/message-content";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/tickets";
@import "../assets/styles/components/project-list";
@import "../assets/styles/components/product-tile";
@import "../assets/styles/components/user-listing";
@import "../assets/styles/components/landing";
@import "../assets/styles/components/chats";
@import "../assets/styles/components/kanban";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/contex-menu";
@import "../assets/styles/components/plugin-overrides/no-ui-slider";
@import "../assets/styles/components/plugin-overrides/slick-carousel";
@import "../assets/styles/components/plugin-overrides/rating";
@import "../assets/styles/components/plugin-overrides/date-picker";
@import "../assets/styles/components/plugin-overrides/wizard";
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/sweet-alert";
@import "../assets/styles/components/plugin-overrides/full-calendar";
@import "../assets/styles/components/plugin-overrides/react-photo-gallery";
@import "../assets/styles/components/plugin-overrides/react-tag-autocomplete";
@import "../assets/styles/components/plugin-overrides/jquery-jvectormap";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";

@media print {
  .pdfprint {
    display: block;
  }
}

.msl-wrp {
  .msl-vars {
    --menu-max-height: 400px;
    --input-width: 300px;
    --font-size: 16px;
    --border-radius: 0px 0px 0px 0px !important ;
    --active-menu-shadow: 0 2px 8px 0px #ebedf2;
    --line-height: 1.4;
    --active-menu-background: #fff;
    --active-menu-radius: var(--border-radius);
  }
}
.breadcrumps_bg {
  background: #efefef;
    padding: 5px ;
}
.cursor_pointr {
  cursor: pointer;
}
.text-red {
  color: red;
  font-size: 10px;
  font-weight: bold;
  
}
.ml_15rem {
  margin-left: 1.4rem;
}

.datefilters {
  width: 100% !important;
  max-width: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  [class*="fa-"] {
    font-size: 22px;
    margin-left: 10px;
    cursor: pointer;
  }
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    /* width: 100%; */
  }
}

.react-datepicker-wrapper {
  display: inline-block;
  border: 2px black;
  width: 100%;
  margin-right: 5px;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999 !important;
  background: rgba(255, 255, 255, 0.3);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropdownform {
  height: 2.2rem;
  padding: 0.5rem 0.81rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.datefilter {
  width: 12rem;
  max-width: 88%;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto;
}

.react-datepicker-wrapper {
  display: inline-block;
  // padding: 10px;
  border: 2px black;
  width: 100%;
  margin-right: 5px;
}

.log-pages {
  background: #fff;
  position: relative;
  display: flex;

  .logo {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 154px;
  }

  label {
    font-size: 14px;
  }

  select.form-control {
    outline: 0 !important;
  }

  .banner-sec {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ccc;
  }

  .form-sec {
    width: 50%;
    display: flex;
    color: #1a1a1a;
    font-size: 15px;

    p {
      font-size: 15px;
    }

    .log-form {
      width: 100%;
      max-width: 472px;
      margin: auto;
    }

    .title {
      font-size: 34px;
      font-weight: 700;
      margin-bottom: 14px;
    }

    .sub-head {
      margin-bottom: 44px;
    }

    .inputs-wrapper {
      margin-bottom: 70px;
    }

    .input-group {
      position: relative;
      margin-bottom: 36px;

      label {
        position: absolute;
        bottom: 0;
        margin-bottom: 8px;
        pointer-events: none;
        opacity: 0.5;
        transition: 0.3s;
      }

      &.selectlabel label {
        margin-bottom: 30px;
        margin-left: 4px;
      }

      &.isfocus label {
        font-size: 14px;
        bottom: 31px;
      }

      &.isfocuss label {
        font-size: 14px;
        bottom: 40px;
      }

      .forgot {
        position: absolute;
        bottom: 0;
        right: 0;
        text-decoration: none;
        color: #fc9200;
        font-size: 18px;
        padding: 8px 0;
      }
    }

    input,
    select {
      width: 100%;
      height: 30px;
      font-size: 15px;
      color: #1a1a1a;
      border: none;
      padding: 8px 0;
      border-bottom: 1px solid rgba(26, 26, 26, 0.12);
    }

    .sign-with {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 52px;

      a {
        color: #fc9200;
        text-decoration: none;
        font-size: 32px;
        margin-left: 2.2vw;
      }
    }

    .btns {
      display: flex;

      .btn {
        flex: 1;
        margin: 0;
        border-radius: 0;
        font-size: 18px;
      }

      .log-btn {
        font-weight: 400;

        i {
          font-size: 80%;
        }
      }

      .sign-btn {
        border: 1px solid #d4d4db;
        text-align: left;
        font-weight: 600;
        color: inherit;

        small {
          display: block;
          margin-bottom: 8px;
          font-size: 72%;
          opacity: 0.7;
        }
      }
    }
  }
}

img {
  max-width: 100%;
}

.group-header {
  padding: 1rem;
  margin-bottom: 0;
  color: #fff;
}

.group-card {
  box-shadow: 0 10px 22px -7px #0000002e;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  overflow: hidden;
}

.MTableHeader-header-13,
.MuiTableCell-head {
  background-color: #f9fafb !important;
  font-size: 100%;
}

.MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
  font-size: 80% !important;
}

.MuiToolbar-gutters {
  padding-left: 16px !important;
}

.nav-tabs .nav-link {
  font-size: 0.7em !important;
}

.MuiTypography-h6 {
  font-size: 120% !important;
}

body .nav-tabs {
  border: none;

  .nav-link {
    padding: 10px 12px;
    border: none;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    min-width: 100px;
    margin-bottom: 0;
    color: #000;
    text-align: center;
    background: whitesmoke;

    &.active::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 100%;
      height: 10px;
      width: 10px;
      color: #fc9200;
      background: inherit;
      clip-path: polygon(
        10% 33%,
        26% 59%,
        44% 76%,
        67% 90%,
        100% 100%,
        0 100%,
        0 0
      );
    }

    &:hover {
      background-color: #919191;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    z-index: 1;
    position: relative;
  }
}

.modal-dialog.form-modal {
  @media (min-width: 992px) {
    max-width: 900px;
  }

  .modal-content {
    background-color: #fff;
  }

  .modal-body {
    padding: 1.5rem;
  }
}
@media (width: 1024px) {
  .content-wrapper {
    padding: 1rem 1rem !important;
  }
}

.content-wrapper {
  padding: 1rem 1rem !important;
}

.modal-dialog.table-modal {
  @media (min-width: 992px) {
    max-width: 850px;
  }

  .modal-content {
    background-color: #fff;
  }

  .modal-body {
    padding: 1.5rem;
  }
}

.form-control {
  border-color: #d6dae3;
}

select.form-control {
  color: #495057;
  outline-color: #d6dae3;

  &:focus {
    outline-color: #80bdff;
  }

  &.is-invalid {
    outline-color: #dc3545;
  }
}

.search-data {
  max-width: 400px;

  .form-control {
    background: #fff url(../assets/images/search.svg) no-repeat right 10px
      center;
  }
}

.counter {
  position: relative;
  max-width: 260px;
  margin: auto;

  .form-control {
    padding: 14px 46px;
    text-align: center;
  }

  .btn {
    position: absolute;
    top: 0;
    height: 100%;
    margin: 0;
    padding: 14px 18px;

    &:first-child {
      left: 0;
    }

    &:nth-child(2) {
      right: 0;
    }
  }
}

.font12 {
  font-size: 12px !important;
}

.filter-bar {
  width: 360px;
}

.filter-header {
  font-weight: 700;
  color: #646464;
  margin-bottom: 2.5rem;
}

.filter-group {
  padding: 14px 20px;
  color: #656c72;
  font-size: 14px;
  margin-bottom: 2rem;
  border: 1px solid #e9e9e9;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  p {
    font-size: 14px;
  }

  p:last-child,
  ul:last-child {
    margin-bottom: 0;
  }

  ul {
    list-style: none;
    padding-left: 14px;

    button {
      border: none;
      background: none;
      padding: 0;
    }
  }

  .filter-by {
    position: relative;
    margin: 0.9rem 0;
    border: 1px solid #000;
    padding: 12px 30px 12px 14px;

    .mdi-close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      font-size: 24px;
      cursor: pointer;
      color: #919191;
    }
  }

  .filter-title {
    font-weight: 700;
    color: #343a40;
    margin-bottom: 1.5rem;

    &:before {
      content: "";
      display: inline-block;
      border-top: 7px solid currentColor;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      margin-right: 6px;
      vertical-align: 1px;
    }
  }

  .more {
    color: #ffa53b;
    padding: 0 14px;
    text-decoration: none;
  }
}

ul.tags {
  list-style: none;
  padding: 0;
  margin: 0 -5px 1rem;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 5px;
    border: 1px solid #e0e2e9;
    padding: 8px 18px;
  }
}

.property-top {
  color: #858585;
  margin-bottom: 27px;
}

.stars {
  background: url(../assets/images/star-nocolor.png) no-repeat;
  width: 112px;
  height: 15px;

  .progress {
    background: url(../assets/images/star-color.png) no-repeat;
    height: 100%;
  }
}

.prop-item {
  max-width: 250px;
  margin: 0 auto 40px;
  cursor: pointer;

  img {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }

  .price {
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 700;
  }
}

.layout-style {
  font-size: 24px;

  .active {
    color: #ff8e0a;
  }
}

.sort_by {
  border: 1px solid #d6dae3;
  padding: 8px 12px;
  font-size: 14px;

  select {
    border: none;
    font-weight: 700;
  }
}

.property-modal.modal-dialog {
  max-width: 700px;

  .modal-body {
    padding: 1.5rem;
  }

  .modal-content {
    border-radius: 0;
    background: #fff;
  }

  .title {
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 14px;
  }

  .price {
    font-weight: 700;
  }
}

.btn-cst {
  background: #ff8e0a;
  color: #fff;

  &:hover {
    background: #ed840a;
    color: #fff;
  }
}

.like {
  color: #767676;
  padding: 4px;
  font-size: 26px;
  line-height: 1;
  border: 1px solid #cdcfd7;
  border-radius: 50%;
  height: 36px;
  background: #fff;
}

.slick-slide img {
  width: 100%;
}

.thumb-slider {
  width: 14%;

  &.slick-vertical .slick-slide {
    padding-bottom: 12%;
  }
}

.banner-slider {
  width: 86%;
  padding-left: 35px;

  &.slick-slider .slick-arrow.slick-prev {
    left: 55px;
  }
}

.slick-head {
  font-size: 1.4rem;
  font-weight: 400;
}

.pointer {
  cursor: pointer;
}

.modal-header {
  padding: 1rem 1.5rem;
  .close {
    margin: -15px -26px -25px auto !important;
  }
}

.text-end {
  text-align: end;
}

.confirm-box {
  z-index: 99999 !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.datefilter {
  width: 300px;
  max-width: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;

  [class*="fa-"] {
    font-size: 22px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.amenity-img {
  width: 56px;
  height: 56px;
  object-fit: contain;
}

.tabcolor .nav-link.active {
  background-color: #fc9200 !important;
}

.rsource input {
  padding: 5px !important;
}

.corps .MuiAccordion-rounded {
  box-shadow: none;
  border: 1px solid #ddd;
}

.tab-content {
  padding: 1rem 1rem !important;
  border: none !important;
}

.exclusionss .MTableToolbar-root-23 {
  display: none;
}

#uncontrolled-tab-example-tabpane-mapproperties,
#uncontrolled-tab-example-tabpane-onboardcorporate,
#uncontrolled-tab-example-tabpane-onboardemployee {
  .jss30 {
    background-color: #fff !important;
    border-radius: 0% !important;
  }

  .jss31 {
    opacity: 1 !important;
  }

  .jss32 {
    position: relative !important;
  }

  .jss23 {
    color: rgba(0, 0, 0, 0.87);
    display: none;
  }
}
#uncontrolled-tab-example-tabpane-mapproperties {
  .MuiBox-root.jss23 {
    display: block !important;
  }
  .jss20,
  .jss21,
  .jss22,
  .jss24 {
    color: rgba(0, 0, 0, 0.87);
  }
}

#uncontrolled-tab-example-tabpane-uploaddocuments {
  .jss30 {
    background-color: #fff !important;
    border-radius: 0% !important;
  }

  .jss31 {
    opacity: 1 !important;
  }

  .jss32 {
    position: relative !important;
  }
}

.corporates {
  .MuiAccordion-root {
    border: 1px solid #ebedf2 !important;
  }

  .MuiAccordion-root.Mui-expanded:first-child {
    box-shadow: 0 1px 15px 1px rgb(230 234 236 / 35%);
  }
  // .MuiToolbar-regular {
  //   display: none !important;
  // }
}

.jss10:hover {
  background-color: transparent;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #007bff !important;
}

.multiselct {
  .kn-multi_select__wrapper___30BEc {
    border: none !important;
  }

  .kn-column__column___3Iwzx {
    border: 1px solid #ddd !important;
  }

  .kn-list__list___22Wuc {
    border-right: 1px solid #ddd;
  }

  .kn-column__column___3Iwzx:last-of-type {
    position: relative;
    margin-left: 23px;
  }
}

.ReactTable .-even {
  background-color: #eee;
}
.MuiIconButton-colorInherit {
  font-size: 14px !important;
}

.IncExc .MuiTableCell-head div {
  justify-content: flex-start !important;
  margin-left: 1px;
}
.IncExc .MuiTableCell-body {
  justify-content: flex-start !important;
  max-width: 20%;
}

.MuiTable-root {
  .MuiFormControl-root {
    width: 100%;
  }
}

.incexcc {
  .MuiPaper-outlined {
    cursor: move;
  }
}

.corpos {
  .card {
    margin-bottom: 0.75rem;
    box-shadow: 0px 1px 15px 1px rgb(230 234 236 / 35%);
    border-radius: 0.25rem;
  }
}

.uploaddoc .MuiToolbar-root button.MuiIconButton-colorInherit {
  position: absolute;
  top: -55px;
  right: 0;
}

.uploaddoc .MuiToolbar-root button.MuiIconButton-colorInherit {
  background-color: #fc9200 !important;
  border-color: #fc9200;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem !important;
  line-height: 1;
  font-weight: 700;
  padding: 15px 25px;
}

.uploaddoc .MuiToolbar-root button.MuiIconButton-colorInherit:hover {
  background-color: #bc6d00 !important;
  border-color: #bc6d00;
}

.uploaddoc {
  .MuiSvgIcon-root {
    width: 0.7em;
    height: 0.7em;
  }
}

.react-pdf {
  max-width: 800px;
  width: 100%;
  display: table;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 30px;
}

.desc:first-letter {
  text-transform: capitalize !important;
}

.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(60% - 40px);
  z-index: 9999;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #fc9200;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.notification_danger {
  padding: 2px;
  background-color: red;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  width: 17px;
  position: absolute;
  font-size: 11px;
  right: -19px;
  text-align: center;
}

// .multiselect .kn-multi_select__wrapper___30BEc {
//   height: 200px !important;
// }

.multiselect {
  > div {
    height: 246px !important;
  }
  .kn-list__list___22Wuc {
    height: 200px !important;
  }
  .kn-multi_select__wrapper___30BEc {
    height: 246px !important;
  }
}

.modal-footer {
  z-index: 99999 !important;
}

.invoicetab {
  .MuiTableSortLabel-root {
    white-space: nowrap !important;
  }

  tfoot th,
  tfoot td {
    border-top: 0px !important;
  }
}

tfoot th,
tfoot td {
  border-top: 0px !important;
}

.invoicecard {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.kn-selected_item__selected_item___1uiiv,
.kn-selected_item__with_grouping___1Ibnd {
  pointer-events: none;
}

.kn-selected_item__selected_item___1uiiv button,
.kn-selected_item__with_grouping___1Ibnd button {
  pointer-events: all !important;
}

.kn-multi_select__wrapper___30BEc
  .kn-column__column___3Iwzx:nth-child(2)
  .kn-list__list_item___1mDV9 {
  pointer-events: none;
}

.sidebar {
  text-transform: capitalize !important;
  overflow-y: auto;
  height: 97vh;
}
.swal2-modal .swal2-actions button.swal2-styled.swal2-cancel.custom-cancel {
  background: rgba(62, 75, 91, 0.2) !important;
  color: #3e4b5b !important;
}
.swal2-modal .swal2-actions button.swal2-styled.swal2-confirm.custom-confirm {
  background: #fc9200 !important;
  color: #fff !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #49a557;
  background-color: #49a557;
}

.jss30 {
  border-radius: 0 !important;
  background-color: transparent !important;
}

.popUp {
  width: 70%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.componentWraper {
  position: relative;
  border: 2px solid black;
  border-radius: 12px;
  padding: 15px;
}

.componentWraper .componentTitle {
  position: absolute;
  top: -12px;
  background: #fff;
  padding: 0 10px;
}

.equalHeight {
  display: grid;
  grid-auto-flow: column;
  gap: 2%;
}

.componentWraper {
  .form-check .form-check-label {
    margin-left: 0px !important;
  }
}

.was-validated .form-check-input:valid ~ .form-check-label {
  color: #000 !important;
}

.was-validated .form-control:valid {
  background-image: none !important;
  border: 1px solid #d6dae3 !important;
}

@media only screen and (max-width: 750px) {
  .popUp {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1000px) {
  .popUp {
    width: 85% !important;
  }
}

.tablebook .MuiTableCell-head,
.propertytable .MuiTableCell-head {
  white-space: nowrap;
}

.MuiTableCell-sizeSmall {
  padding: 13px 0px 13px 10px !important;
}

.MuiSelect {
  top: calc(50% - 8px) !important;
}

.userIdentity {
  font-size: 16px;
  font-weight: 400;
  padding: 2px 12px;
}

.zoomIcon {
  zoom: 1.2;
}

@media screen and (max-width: 991px) {
  .sidebar-offcanvas.active {
    width: 100%;
  }
}

.navbar
  .navbar-menu-wrapper
  .navbar-nav
  .nav-item
  .dropdown
  .dropdown-menu.navbar-dropdown
  .dropdown-item {
  padding: 3px 12px !important;
}

.imgCentered {
  position: absolute;
  top: 52%;
  left: 56%;
  transform: translate(-62%, -46%);
}

.imgTitle {
  position: absolute;
  bottom: 145px;
  font-weight: bold;
}

//visitor management
.visitorManagementTitle {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 2rem;
  color: #fc9200;
}
.searchStyle .MTableToolbar-title-8 {
  // position: absolute;
  // right: 16px;
  // display: inherit;
  color: dodgerblue;
  font-size: 18px !important;
  font-weight: 700;
}

.visitor_icon {
  font-size: 20px;
  margin-right: 0.1rem;
}
.visitorpage button {
  color: #fff;
  background-color: #fc9200;
  border-color: #fc9200;
}
.visitorpage button:hover {
  color: #bc6d00 !important;
  background-color: #fff !important;
  border: 0.5px solid #bc6d00 !important;
}
.asn_btn:hover {
  color: #bc6d00 !important;
  background-color: #cfc8c8;
  border: 0.5px solid #bc6d00 !important;
}

// .MTableToolbar-title-8::before {
//   content: "\002B";
//   font-size: 150%;
//   margin-right: 5px;
//   background-color: #007bff;
//   color: #fff;
//   padding: 1px 1px 3px 1px;
//   border-radius: 50%;
// }
.visitors_modal_input {
  margin-bottom: 10px;
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 3px solid #adb5bd;
  padding: 10px;
  //new
  border-color: #d6dae3;
  border: 1px solid #ebedf2;
  font-size: 0.8125rem;
  color: #495057;
  background-color: #ffffff;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.visitors_modal_input:focus {
  border-color: #adb5bd;
  //new
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
}
.updateGuestForm .visitors_modal_input {
  padding: 10px;
}
.inviteGuestForm .visitors_modal_input {
  padding: 10px;
}
.visitors_modal_lable {
  text-transform: capitalize;
  font-family: "Ubuntu", sans-serif;
  font-size: 0.875rem;
  padding-bottom: 5px;
  display: block;
  color: #343a40;
  // font-weight: 600;
  // color: #111;
  font-weight: 500;
}
.visitors_modal_lable_radio {
  padding-right: 2rem;
  //new
  text-transform: capitalize;
  font-family: "Ubuntu", sans-serif;
  font-size: 0.875rem;
  padding-bottom: 5px;
  // display: block;
  color: #343a40;
}
.visitors_modal_textarea {
  margin-bottom: 10px;
  width: 100%;
  border-radius: 3px;
  border: 3px solid #adb5bd;
  //new
  border-color: #d6dae3;
  border: 1px solid #ebedf2;
  font-size: 0.8125rem;
  color: #495057;
  background-color: #ffffff;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.visitors_modal_textarea:focus {
  border-color: #adb5bd;
  //new
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
.inviteGuestModal .modal-dialog {
  max-width: 1000px;
}
.updateGuestModal .modal-dialog {
  max-width: 1000px;
}
.checkInModal .modal-dialog {
  max-width: 800px;
}
.checkInForm,
.inviteGuestForm {
  padding: 0 1rem;
}
/* for radio buttons  */
.addStaff input[type="radio"] {
  visibility: hidden;
  position: absolute;
}
.addStaff input[type="radio"] + label {
  cursor: pointer;
}
.addStaff input[type="radio"] + label::before {
  margin-right: 10px;
  content: " ";
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s;
  border: 1px solid rgb(250, 165, 27);
  border-radius: 10px;
  transition: 0.3s;
}
.addStaff input[type="radio"] + label:before {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.addStaff input[type="radio"]:checked + label:before {
  box-shadow: inset 0px 0px 0px 2px #fff, inset 0px 0px 0px 8px orange;
}
.addStaff input[type="radio"] {
  visibility: hidden;
  position: absolute;
}
.addStaff input[type="radio"] + label {
  cursor: pointer;
}
.addStaff input[type="radio"] + label::before {
  margin-right: 10px;
  content: " ";
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s;
  border: 1px solid rgb(250, 165, 27);
  border-radius: 10px;
  transition: 0.3s;
}
.addStaff input[type="radio"] + label:before {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.addStaff input[type="radio"]:checked + label:before {
  box-shadow: inset 0px 0px 0px 2px #fff, inset 0px 0px 0px 8px orange;
}
.editStaff input[type="radio"] {
  visibility: hidden;
  position: absolute;
}
.editStaff input[type="radio"] + label {
  cursor: pointer;
}
.editStaff input[type="radio"] + label::before {
  margin-right: 10px;
  content: " ";
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s;
  border: 1px solid rgb(250, 165, 27);
  border-radius: 10px;
  transition: 0.3s;
}
.editStaff input[type="radio"] + label:before {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.editStaff input[type="radio"]:checked + label:before {
  box-shadow: inset 0px 0px 0px 2px #fff, inset 0px 0px 0px 8px orange;
}
.inviteGuestForm input[type="radio"] {
  /* hide original inputs */
  visibility: hidden;
  position: absolute;
}
.inviteGuestForm input[type="radio"] + label {
  cursor: pointer;
}
.inviteGuestForm input[type="radio"] + label:before {
  margin-right: 10px;
  content: " ";
  display: inline-block;
  vertical-align: middle;
  transition: 0.3s;
  border: 1px solid rgb(250, 165, 27);
  border-radius: 10px;
  transition: 0.3s;
}
/* DEFAULT */
.inviteGuestForm input[type="radio"] + label:before {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
/* CHECKED */
.inviteGuestForm input[type="radio"]:checked + label:before {
  box-shadow: inset 0px 0px 0px 2px #fff, inset 0px 0px 0px 8px orange;
}

/* for -radio buttons */
/* for date  */

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../assets/images/calendar.png") no-repeat;
  background-size: 100% 100%;
  content: " ";
  width: 24px;
  padding-right: 0;
  display: inline-block;
}
/* for date  */

/* for time  */
input[type="time"]::-webkit-calendar-picker-indicator {
  background: url("../assets/images/clock.png") no-repeat;
  background-size: 100% 100%;
  content: " ";
  width: 16px;
  padding-right: 0;
  display: inline-block;
}
/* for time  */

/* for file  */

input::file-selector-button {
  display: none;
}

.infra_form input[type="file"]::after {
  display: inline-block;
  margin-left: 50%;
  // background-image: url("../../../assets/images/file.png");
  background-size: 100% 100%;
  content: " ";
  width: 24px;
  padding-right: 0;
}

/* for file  */

.searchStyle .MuiTableRow-root:nth-child(even) {
  background-color: #e9ecef;
}

.searchStyle .MuiTableRow-root:nth-child(odd) {
  background-color: #fff;
}
// #visitingInput::-webkit-datetime-edit-ampm-field {
//   display: none;
// }
// input[type=time]::-webkit-datetime-edit-ampm-field {

//   display: none;

//   }
// input[type='time']::-webkit-calendar-picker-indicator {
//   opacity: 0;
//   position: absolute;
//   width: 10%;
//   left:35%;
// }
.visitors_modal_input_flex {
  display: flex;
  column-gap: 10px;
}
.visitor_select_hour {
  position: absolute;
  top: 10px;
  right: 30px;
  // font-weight: 600;
  text-transform: capitalize;
}
.visitor_select_minute {
  position: absolute;
  top: 10px;
  right: 30px;
  // font-weight: 600;
  text-transform: capitalize;
}
.selectParent {
  position: relative;
  width: 100%;
}
.invite_button {
  background-color: dodgerblue !important;
  border: 1px solid dodgerblue !important;
  color: #fff;
}
.invite_button:hover {
  background-color: #74c0fc !important;
  border: 1px solid #74c0fc !important;
}
.invite_button:active {
  box-shadow: 0px 0px 3px 2px #d0ebff !important;
}
.visitor_row_button {
  background: none;
  border: none;
}
#style_toast_container__o6MeG {
  z-index: 2000000 !important;
  position: relative !important;
}
.style_toast-list__378gH {
  bottom: 15% !important;
}
//for rejected toast
// .style_toast-content__kl8dC{
//   background-color: rgba(44, 138, 210,0.8) !important;
//   color: #fff;
// }

.capture_Image {
  border: 1px solid orange;
  margin: 2px;
  padding: 2px;
}
.capture_head {
  text-align: center;
  color: dodgerblue;
  font-weight: 600;
  font-size: 20px;
}
.inputError {
  color: red;
  // font-weight: 600;
  margin-bottom: 5px;
}
.date_error input:out-of-range {
  background-color: #ffe3e3;
}
.date_error input:out-of-range + label::after {
  content: " Enter Valid Date";
}
.time_error input:out-of-range {
  background-color: #ffe3e3;
}
.time_error input:out-of-range + label::after {
  content: " Enter Valid Time";
}

.success .style_toast-content__kl8dC {
  background-color: #07bc0c !important;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.warning .style_toast-content__kl8dC {
  background-color: #f1c40f !important;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.error .style_toast-content__kl8dC {
  background-color: #e74c3c;
  color: #fff;
}
.checkInForm .visitors_modal_input {
  margin-bottom: 5px;
}
.style_toast-content__kl8dC {
  border-radius: 0px !important;
}
.modal-content {
  background-color: #fff !important;
}
.text-danger {
  color: #fe7c96 !important;
  padding-left: 5px;
}
.notDisableInput {
  color: #ffa94d;
}
.extendTime .modal-dialog {
  max-width: 300px;
}
.visitor_empty_list_container {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.visitor_empty_list {
  text-align: center;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
}
.inviteGuestForm option {
  font-size: 16px;
}
.updateGuestForm option {
  font-size: 16px;
}
.checkInForm option {
  font-size: 16px;
}
.confirmModal_parent {
  padding: 2rem 0.5rem;
}

.modal-content .confirmModal_button {
  padding: 1rem 2rem;
  color: #fff;
  border: 1px solid;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
.modal_confirm_text {
  text-align: center;
  padding: 1rem 0;
  font-size: 18px;
  font-weight: 500;
}

/* CONFIRM MODAL */
.visitor_button {
  color: #fff;
  background-color: #fc9200;
  border-color: #fc9200;
}
.visitor_button:hover {
  color: dodgerblue !important;
  background-color: #fff !important;
  border-color: dodgerblue !important;
}
// ********************************************** HELPDESK ****************************************
// ################## LOADER ####################
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #faa619;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
// ################## LOADER ####################

.brh_loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid orange;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.loader_container {
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.issue_raise_table table {
  margin: 1rem 0 2rem 0;
}
.issue_raise_table thead {
  background-color: #ff9232;
  color: #fff;
}
.issue_raise_table th {
  font-weight: 700 !important;
  padding: 1rem;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.issue_raise_table td {
  padding: 1rem 0;
  text-indent: 1rem;
  font-weight: 500;
  font-size: 14px;
}
.issue_raise_page_image {
  border-radius: 5px;
  margin-top: 10px;
}
.issue_image_container {
  margin: 1rem 2rem;
}
.issue_image_button {
  position: relative;
  border: 1px solid orange;
  padding: 1rem;
  padding-top: 2rem;
  background-color: #fff9db;
  border-radius: 5px;
}
.issue_image_close_button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
}
.issue_image_upload_note {
  font-size: 14px;
  margin: 5px 0 0 0;
  color: dodgerblue;
}
.issue_image_upload_title {
  position: absolute;
  top: 5px;
  text-transform: uppercase;
}

.user_management_styled_button {
  display: block;
  background-color: orange;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 5px 10px;
  border: 1px solid orange;
  margin: 1.5rem auto;
  width: 75%;
  background: linear-gradient(45deg, #ffe066, #fab005);
  box-shadow: 3px 4px 2px 1px #ffec99;
  outline: none;
}
.user_management_styled_button:hover {
  background: linear-gradient(225deg, #ffe066, #fab005);
  box-shadow: 3px 4px 2px 1px #ffec99;
}
.user_management_styled_button:active {
  transform: translate(0, 3px);
  box-shadow: 3px 4px 2px 1px #fff;
}
.update_password_basic_button {
  color: #fff;
  padding: 0.5rem 2rem;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 5px;
  margin-left: 2px;
  text-transform: uppercase;
  border: 0px solid #ffa94d;
  background: #ffa94d;
}
.is_update {
  background-color: #fc9200;
  border: 1px solid #fc9200;
}
::-webkit-scrollbar {
  height: 8px;
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #d5d5d5;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: #fc9200;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #fc9200;
  border-radius: 4px;
}
